export const Methods = {
  post: "POST",
  get: "GET",
  delete: "DELETE",
  put: "PUT",
};

export const Urls = {
  login: "users/login",
  loginGetOtp: "users/resendmobileotp/",
  verifyOtp: "users/verifyemailotp",
  refreshToken: "users/refreshtoken",
  addTask: "task/add",
  assignToList: "users/assignlist",
  reportToList: "users/reportinglist",
  relatedTask: "task/getRelatedTask?companyId=",
  getCompanyList: "company/companylist",
  getCompanyUrl: "company/companyUrl",
  getCompanyData: "company/add",
  getStaffOwnerList: "users/owner/list",
  getStaffManagerList: "users/manager/list",
  addStaffEmployee: "users/employee/add",
  accessLogDocument: "accesslogs/documents/list",
  getPendingApprovalList: "task/pendingapproval",
  getRejectedTaskList: "task/rejectedtask",
  getPinnedTaskList: "task/pinnedTaskList",
  masterDefault: "master/defaultvalues",
  timeZone: "/master/timezonelist",
  taskList: "task/mainlist",
  selfTaskList: "task/selfassigned",
  getStaffGeneralManagerList: "users/gm/list",
  addStaffGeneralManager: "users/gm/add",
  viewStaffGeneralManager: "users/gm/view/",
  editStaffGeneralManager: "users/gm/edit/",
  rateStaffGeneralManager: "users/gm/rate/",
  deleteStaffGeneralManager: "users/gm/delete/",
  viewStaffManager: "users/manager/view/",
  editStaffManager: "users/manager/edit/",
  rateStaffManager: "users/manager/rate/",
  deleteStaffManager: "users/manager/delete/",
  getTaskDetail: "task/get/",
  getComments1: "comment/list?taskId=",
  getComments2: "&pageNo=",
  addComment: "comment/add",
  editComment: "comment/edit/",
  deleteComment: "comment/delete",
  editTask: "task/edit",
  taskHistory: "task/taskhistory/",
  getAssignedByPATaskList: "task/assignedbypa",
  getRelatedTaskList: "task/relatedTaskList",
  pinTask: "task/pinnedBy",
  criticalTask: "task/markCritical",
  viewProfile: "users/viewprofile",
  editProfile: "users/editprofile",
  uploadImage: "users/updateimage",
  deleteImage: "users/deleteimage",
  companyDetails: "company/companydetails",
  addStaffManager: "users/manager/add",
  getPersonalAssistantList: "users/pa/list",
  viewStaffOwner: "/users/owner/view/",
  editStaffOwner: "/users/owner/edit/",
  addStaffOwner: "users/owner/add",
  deleteStaffOwner: "/users/owner/delete/",
  addPersonalAssistant: "users/pa/add",
  viewPersonalAssistant: "users/pa/view/",
  editPersonalAssistant: "users/pa/edit/",
  deletePersonalAssistant: "users/pa/delete/",
  ratePersonalAssistant: "users/pa/rate/",
  getEmployeeList: "users/employee/list",
  getMyVoiceNotes: "voicenote/list",
  deleteVoiceNote: "voicenote/delete",
  renameVoiceNote: "voicenote/rename/",
  addVendor: "vendor/add",
  getVendorList: "vendor/list",
  viewVendor: "vendor/view/",
  editVendor: "vendor/edit/",
  deleteVendor: "vendor/delete",
  getSharedWithMeVoiceNotes: "voicenote/sharedwithme",
  getMyDocumentList: "document/list",
  addDocument: "document/add",
  editDocument: "document/edit/",
  viewDocument: "document/view/",
  getSharedDocumentList: "document/sharedwithme",
  getOfficeLocations: "company/officeLocations",
  editOfficeLocations: "company/edit",

  getContactRepoPrivateList: "contact/list/private",
  addTaskReminder: "reminder/add",
  getVoiceNoteReminder: "reminder/add",
  accessLogsListingVoiceNote: "accesslogs/voicenote/list",
  acceptTaskByAssignee: "task/acceptTask",
  rejectTaskByAssignee: "task/rejectTask",
  startTask: "task/startTask",
  startSelfAssignTask: "task/selfassignedStartTask",
  resolveTask: "task/resolveTask",
  approveTaskByReporter: "task/approveTask",
  disapproveTaskByReporter: "task/disapproveTask",
  markTaskAsCompleted: "task/markAsCompleted",
  markAsPublic: "contact/mark/",
  chatList: "chat/list",
  chatDetailMessages: "message/chat/list",
  chatInvitee: "chat/invitee/list",
  addChat: "chat/add",
  sendMessage: "message/add",
  deleteMessage: "message/delete",
  chatDetailInfo: "chat/details",
  groupChatList: "group/list",
  contactRepoAccessLogs: "accessLogs/contacts/list",
  addSubtask: "subtask/add",
  getSubtaskList: "subtask/list/",
  getSubtaskDetails: "subtask/view/",
  editSubtask: "subtask/edit",
  deleteSubtask: "subtask/delete",
  getNotificationList: "notification/list",
  addRenewal: "renewal/add",
  getRenewalList: "renewal/list",
  getRenewalDetails: "renewal/view/",
  editRenewal: "renewal/edit/",
  viewBusinessCard: "contact/view/",
  getSelectMemberlist: "contact/rolefilter",
  addContact: "contact/add",
  editContact: "contact/edit/",
  getReAllocationRequestTaskList: "task/reallocate",
  getOwnerActivityLogs: "activityLogs/owner/list",
  getManagerActivityLogs: "activityLogs/manager/list",
  getEmployeeActivityLogs: "activityLogs/employee/list",
  getGmActivityLogs: "activityLogs/gm/list",
  getPaActivityLogs: "activityLogs/pa/list",
  getVendorActivityLogs: "activityLogs/vendor/list",
  getContactRepoPublicList: "contact/list/public",
  deleteContact: "contact/delete",
  getShareContactInvitee: "contact/invitee/list",
  getShareContact: "contact/share",
  getShareContactPa: "contact/shared/rolefilter",
  groupInvitee: "group/invitee/list",
  addGroup: "group/add",
  groupDetailInfo: "group/details",
  addGroupMember: "group/member/add",
  removeGroupMember: "group/member/remove",
  groupDetailMessages: "message/group/list",
  getReportedByMeTaskList: "task/reportedbyme",
  getSharedList: "contact/shared/list",
  viewSharedContactBusinessCard: "contact/shareview/",
  getTimezoneList: "/master/timezonelist",
  changeTimeZone: "/users/changetimezone",
  inboxList: "email/email/list",
  emailLabelAs: "email/changelabel",
  getShareDocumentInvitee: "document/invitee/list",
  shareDocument: "document/share",
  changePassword: "users/changepassword",
  getTodaysEvent: "dashboard/todaysEvents",
  getInprogressTask: "dashboard/inprogressTask",
  taskProgressBar: "dashboard/task-progress",
  riskFactor: "dashboard/risk-factor",
  logOut: "users/logout/",
  createEvent: "/event/add",
  dayEventList: "event/dayEvents",
  eventDetail: "event/get/",
  editEvent: "event/edit",
  eventInvitee: "event/invitee/list",
  deleteEvent: "/event/delete",
  getCalendarInviteeList: "users/staff/list",
  getMarkedEventList: "event/markedList",
  viewInboxEmailDetails: "email/view/",
  shareVoiceNotes: "voicenote/share",
  shareVoiceNotesInviteeList: "voicenote/invitee/list",
  getTaskReportGraphDetails: "report/task/graph",
  getTaskReportList: "report/task/list",
  exportTaskReportList: "report/task/list/export",
  getWorkloadReportList: "/report/workload/list",
  getAttendanceReportList: "/report/attendance/list",
  getWorkloadReportDetails: "report/workload/details",
  vendorsReportingList: "users/reportto/list",
  performanceReport: "report/performance/list",
  performanceReportDetails: "report/performance/details",
  getEmailRelatedTaskList: "email/relatedtask",
  reminderToList: "reminder/reminderTo/list",
  voiceNotesAddAccessLogs: "accesslogs/add",
  viewEmployee: "users/employee/view/",
  rateEmployee: "users/employee/rate/",
  editEmployee: "users/employee/edit/",
  deleteEmployee: "users/employee/delete/",
  getPrivacyDetails: "cms/privacy",
  getTermsAndConditionDetails: "cms/termsAndConditions",
  getAboutUsDetails: "cms/aboutus",
  getGroupMemberList: "group/members/list",
  reassignTask: "task/reassignuser",
  getStaffPerformanceDetails: "users/staff/performance",
  addConfiguration: "/attendance/configuration/add",
  getChatGroupRelatedTaskList: "group/relatedtask",
  getChatRelatedTaskList: "chat/relatedtask",
  getStaffReportList: "report/staff/list",
  getStaffReportDetails: "report/staff/details",
  editGroupName: "group/updatename/",
  attendanceDetail: "report/attendance/detail",
  addLeave: "attendance/leave/add",
  uploadGroupIcon: "group/updateimage/",
  exportStaffReports: "report/staff/list/export",
  deleteGroupIcon: "group/deleteimage/",
  selectMemberPrivateContacts: "contact/privatelist",
  getCompanyStructureDetails: "company/structure",
  getContactInviteeTreeList: "contact/invitee/list/treeview",
  getAssignedToMeTaskList: "task/assignedto/me",
  workLoadExports: "report/workload/list/export",
  attendanceExports: "report/attendance/list/export",
  performanceExports: "report/performance/list/export",
  deleteTask: "task/delete",
  getShareRoleFilter: "contact/shared/rolefilter",
  reAllocateTask: "task/reallocate/request",
  disapproveReAllocateTaskReq: "task/reallocaterequest/disapprove",
  reAllocateTaskToAssignee: "task/assignuser",
  reportedByPA: "task/reportedby/pa",
  addGroupFromTask: "group/task/add",
  requestContactList: "contact/request-to-user/list",
  requestContact: "contact/request",
  getReportingStructureDetails: "users/reporting/structure",
  updateFmcToken: "notification/web/manage",
  assignTaskFromRejected: "task/assignuser",
  sendEamilOTP: "users/sendemailotp",
  verifyEmailOTP: "users/verifyemailotp",
  setNewPassword: "users/setpassword",
  getTaskDependancy: "task/dependency",
  viewAttendance: "attendance/configuration/view",
  attendanceDetailExport: "report/attendance/detail/export",
  shareTask: "task/share",
  reportToListStaff: "users/reportto/list",
  getOwnerActivityLogsExport: "activityLogs/owner/list/export",
  getPAActivityLogsExport: "activityLogs/pa/list/export",
  getGMActivityLogsExport: "activityLogs/gm/list/export",
  getManagerActivityLogsExport: "activityLogs/manager/list/export",
  getEmployeeActivityLogsExport: "activityLogs/employee/list/export",
  getVendorActivityLogsExport: "activityLogs/vendor/list/export",
  editVendorProfile: "vendor/editprofile",
  trackChangeLanguage: "users/changelanguage",
  getLeaveRequestList: "attendance/leave/request/list?pageNo=",
  processLeaveRequest: "attendance/leave/request/changestatus",
  inviteeSelect: "inviteesession/select",
  addMom: "eventmom/add",
  getMomList: "eventmom/list",
  getMomDetail: "eventmom/details",
  editMom: "eventmom/edit",
  deleteMom: "eventmom/delete",
  shareMom: "eventmom/share",
  sharedWithMeMomListing: "eventmom/shared/list",
  getMOMInviteeList: "eventmom/invitee/list",
  getSharedMomUsersList: "eventmom/shareduser",
  getHrOfficerList: "users/officer/list",
  addHrOfficer: "users/officer/add",
  viewHrOfficer: "users/officer/view/",
  editHrOfficer: "users/officer/edit/",
  getEmployeeRequestList: "task/employeetaskrequest",
  approveTaskByManager: "task/approveEmployeeSelfAssignTask",
  disapproveTaskByManager: "task/disapproveEmployeeSelfAssignTask",
  getProcurements: "users/PO/list",
  addPO: "users/PO/add",
  editPo: "users/PO/edit/",
  viewPo: "users/PO/view/",
  addProject: "project/add",
  getProjectReportList: "project/reports/list",
  getProjectDropdownList: "project/dropdown",
  generateCustomReport: "customreport/generateReport",
  getAssignedByOwnerList: "customreport/owner",
  getAssignedByGmList: "customreport/gmManager",
  addProcurementRequest: "request/add",
  getRequestForList: "request/list/roles",
  getProcurementPendingList: "request/pendingRequests/list",
  getViewProcurementDetails: "request/view/",
  approveDisapproveApi: "request/approval/",
  getMyRequestListApi: "request/myRequests/list",
  getPORequestListApi: "request/list",
  applyLeave: "leave/add",
  leaveList: "leave/request/pending",
  myLeaveRequest: "leave/request/my",
  getMyLeaveDetail: "leave/details/",
  approveDisapproveleave: "leave/request/pending/approve",
  disapproveleave: "leave/request/pending/disapprove",
  deletePORequest: "/request/delete/",
  updatePORequest: "/request/edit/",
};

const OWNER = "Owner",
  GENERAL_MANAGER = "GM",
  MANAGER = "Manager",
  EMPLOYEE = "Employee",
  VENDOR = "Vendor",
  PA = "Personal assistant",
  GM = "General manager",
  SELF = "Self",
  HEIF = "heif",
  HEIC = "heic",
  ASSIGN_TO_ME = "Assign to me",
  REPORT_TO_ME = "Report to me",
  PERSONALASSISTANT = "PA";

export const userType = {
  Owner: OWNER,
  GeneralManager: GENERAL_MANAGER,
  Manager: MANAGER,
  Employee: EMPLOYEE,
  Vendor: VENDOR,
  PersonalAssistant: PERSONALASSISTANT,
};

export const userTypes = [OWNER, GENERAL_MANAGER, MANAGER, EMPLOYEE, VENDOR];

export const eventActionType = {
  CREATE_EXPIRY: "Create-expiry",
  SEND_MESSAGE: "Send-Message",
  SHARE_CONTACT: "Contact-Share",
  ADD_GROUP_MEMBER: "Add-Member",
  REMOVE_GROUP_MEMBER: "Remove-Member",
  SHARE_DOCUMENT: "Document-Share",
  TASK_REMINDER: "Task-reminder",
  VOICE_NOTE_SHARE: "VoiceNote-Share",
  CONTACT_EDITED: "Contact-Edited",
  DELETE_TASK: "Delete-Task",
  LEAVE_APPROVE: "Leave-approve",
  LEAVE_APPROVE_DISAPPROVE: "Leave-approve-disapprove",
  EDIT_USER: "Edit-user",
  SHARE_MOM: "MOM-Share",
  ADDED_LEAVE_REQUEST: "ADDED_LEAVE_REQUEST",
  ADDED_PO_REQUEST: "ADDED_PO_REQUEST",
  APPROVAL_PO_REQUEST: "APPROVAL_PO_REQUEST",
  ADD_REQUEST: "ADD_REQUEST",
};

// for filter
export const userTypeOptions = {
  SELF,
  OWNER,
  PA,
  GM,
  MANAGER,
  EMPLOYEE,
  VENDOR,
};
export const Regex = {
  passwordRegexWithoutSequence:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,15})/,
  mentionWithDelimiter: /(@\[([A-Za-z0-9 ]+)\]\(\w+\))/,
  mentionNoDelimiter: /@\[([A-Za-z0-9 ]+)\]\((\w+)\)/g,
  urlRegex: /^(http(s)?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w- ;,./?%&=]*)?$/,
};

// common country code
export const jordanCountryCode = "962";
export const jordanCountryShortCode = "JO";
export const indiaCountryCode = "91";
export const indiaCountryShortCode = "IN";

export const languageType = {
  english: "english",
  arabic: "arabic",
};

export const iosImgType = { HEIF, HEIC };

export const iosImgTypes = [HEIF, HEIC];

export const userDefaultDropdownItem = { ASSIGN_TO_ME, REPORT_TO_ME };
