import { Stack } from "@mui/material";
import React from "react";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { StyledTypography, ErroLabelStyle } from "../../common/Utils/StyledComponents";
import {
  LocalizationProvider,
  TimePicker,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Translate from "../../translation/utils/translateFunction";
const Datepicker = ({
  className = "form-control",
  dayPlaceholder = Translate("common:day"),
  monthPlaceholder = Translate("common:month"),
  yearPlaceholder = Translate("common:year"),
  hourPlaceholder = Translate("common:hour"),
  minutePlaceholder = Translate("common:minute"),
  format = "yyyy-MM-dd",

  onChange,
  maxDate = new Date("9999-12-31"),
  minDate,
  containerStyle = "",
  label,
  selected,
  defaultDate,
  clearIcon = null,
  value,
  touched,
  error,
  RenderRightContainer,
  onShow,
  show,
  required,
  isRowError = false,
  disabled,
  isTimePicker = false,
  ...props
}) => {
  const handleChange = (date) => {
    onChange(date);
  };

  return (
    <Stack>
      <StyledTypography className="labelStyling" component="span">
        {label}
        {required && (
          <StyledTypography sx={{ color: "red" }} component="span">
            {" *"}
          </StyledTypography>
        )}
      </StyledTypography>
      <Stack position={"relative"}>
        {!isTimePicker ? (
          <DateTimePicker
            {...props}
            className={className}
            dayPlaceholder={dayPlaceholder}
            yearPlaceholder={yearPlaceholder}
            monthPlaceholder={monthPlaceholder}
            hourPlaceholder={hourPlaceholder}
            minutePlaceholder={minutePlaceholder}
            format={format}
            value={value}
            onChange={handleChange}
            maxDate={maxDate}
            clearIcon={clearIcon}
            minDate={minDate}
            isClockOpen={show}
            disableClock={disabled}
            disabled={disabled}
          />
        ) : (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              {...props}
              slotProps={{
                textField: {
                  size: "small",
                  placeholder: format?.toUpperCase(),
                  className: className,
                },
              }}
              sx={{
                "& .MuiInputBase-input": {
                  height: "27px",
                  fontWeight: "300",
                },
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  borderColor:
                    touched && error ? "#dc3545" : "rgba(0, 0, 0, 0.23)",
                },
                "& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root": {
                  outline: "none",
                },
              }}
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
              }}
              onChange={handleChange}
              format={format}
              value={value}
              disabled={disabled}
            />
          </LocalizationProvider>
        )}
        {RenderRightContainer && (
          <div
            className={"iconStyling rightIconStyle"}
            onClick={() => onShow?.(!show)}>
            {RenderRightContainer}
          </div>
        )}
      </Stack>
      {touched && error && (
        <StyledTypography sx={ErroLabelStyle} component="span">
          {error}
        </StyledTypography>
      )}
    </Stack>
  );
};

export default Datepicker;
