export default {
  addProcurementRequest: "Add request",
  editProcurementRequest: "Edit request",
  request: "Request",
  addCompany: "Select company",
  title: "Title",
  addTitle: "Add title",
  vendorName: "Vendor name",
  vendorContactPersonName: "Vendor contact person",
  vendorContactPersonNamePlaceholder: "Contact person",
  quantity: "Quantity",
  addQuantity: "Add quantity",
  unitPrice: "Unit price",
  unitPricePlaceholder: "Price per unit",
  totalCost: "Total cost",
  totalCostPlaceholder: "Total cost",
  procurementJustification: "Justification for Procurement",
  procurementJustificationPlaceholder:
    "Explanation of why the item/service is needed",
  email: "Email",
  procurementDescription: "Item/Service Description",
  procurementDescriptionPlaceholder:
    "Add description of item/service to be procured",
  deliveryAddress: "Delivery address",
  deliveryDate: "Preferred delivery date",
  requestTo: "Request to",
  requestBy: "Request by",
  vendorInfo: "Vendor Information",
  procurementDetails: "Procurement details",
  deliveryInfo: "Delivery information",
  requestForError: "Request is required",
  vendorNameError: "Vendor name is required",
  vendorContactPersonNameError: "Vendor contact person name is required",
  emailError: "Email is required",
  quantityError: "Quantity is required",
  unitPriceError: "Unit price is required",
  totalCostError: "Total cost is required",
  justificationError: "Justification is required",
  addressError: "Delivery address is required",
  countryError: "Country is required",
  stateError: "State is required",
  preferredDeliveryDateError: "Preferred delivery date is required",
  startDateError: "Start date is required",
  startTimeError: "Start time is required",
  dueDateError: "Due date is required",
  dueTimeError: "Due time is required",
  fileAddLimitMsg: "Maximum 5 files are allowed",
  totalCostIncorrectError: "Total cost is product of quantity and unit price",
  PendingApprovals: "Pending approvals",
  requestID: "Request ID",
  requestTitle: "Request title",
  requestBy: "Request by",
  dueDateAndTime: "Due date and time",
  companyName: "Company name",
  priorityError: "Priority is required",
  company: "Company",
  contactNumber: "Contact number",
  zipcode: "Zip code",
  name: "Name",
  designation: "Designation",
  status: "Status",
  approveAlert: "Are you sure you want to approve the request?",
  disapproveAlert: "Are you sure you want to disapprove the request?",
  disapproveReason: "Reason for disapprove the request:",
  approveTitle: "Approve",
  disapproveTitle: "Disapprove",
  MYREQUEST: "My request",
  PENDINGREQUEST: "Pending approvals",
  disapproveReason: "Reason for disapproved request",
  deleteTitle: "Are you sure you want to delete this request?",
  invalidEmailError: "Email id is invalid",
  dueDateSameAsStartDateError: "Due date should not be equals to start date",
  requestTo: "Request to",
  noCompanyMessage: "Procurement for all the companies are created",
};
