import { useField } from "formik";
import React, { useEffect } from "react";
import Datepicker from "../../common/datepicker";
import dayjs from "dayjs";

const FormikDatePicker = ({ name, onChange, ...props }) => {
  const [field, meta, helpers] = useField(name);
  const { error, touched } = { ...meta };
  const { value } = field;
  const dateValue = props.isprofile ? value && new Date(value) : value;
  useEffect(() => {
    if (dateValue) {
      setTimeout(() => {
        helpers.setError(undefined);
      }, 100);
    }
  }, [value]);
  return (
    <Datepicker
      {...props}
      value={dateValue}
      className={
        touched && error
          ? `form-control dateFieldHeight paddingStyle ${props.disabled ? "disableStyling dateDisable" : ""
          } is-invalid`
          : `form-control dateFieldHeight paddingStyle ${props.disabled ? "disableStyling dateDisable" : ""
          }`
      }
      isError={touched && error !== undefined}
      touched={touched}
      error={error}
      disable={props?.disabled}
      onChange={(textValue) => {
        if (dayjs(textValue).format() !== "Invalid Date" && textValue) {
          helpers.setValue(textValue);
          onChange(textValue);
        } else {
          textValue === "Invalid Date" && textValue && onChange("");
          props?.isTimePicker && dayjs(textValue).format() === "Invalid Date" && onChange(null);
          props?.isTimePicker
            ? dayjs(textValue).format() === "Invalid Date" &&
            helpers.setValue(null)
            : helpers.setValue("");
        }
      }}
      isRowError={props?.isRowError}
    />
  );
};

export default FormikDatePicker;
