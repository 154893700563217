export default {
  dashboard: "Dashboard",
  contactRepository: "Contact repository",
  documentRepository: "Document repository",
  report: "Report",
  taskReport: "Task report",
  performanceReport: "Performance report",
  workloadReport: "Workload report",
  staffReport: "Staff report",
  vendorsSuppliers: "Vendors/Suppliers",
  aboutUs: "About us",
  privacy: "Privacy policy",
  Privacy: "Privacy",
  termsConditions: "Terms & conditions",
  logout: "Logout",
  staff: "Staff",
  owner: "Owner",
  manager: "Manager",
  employee: "Employee",
  vendor: "Vendor",
  attendanceReport: "Attendance report",
  voiceNote: "Voice note",
  activityLogs: "Activity logs",
  renewals: "Renewals",
  organizationStructure: "Organization Structure",
  companyStructure: "Company Structure",
  reportingStructure: "Reporting Structure",
  company: "Company",
  notifications: "Notifications",
  settings: "Settings",
  myVoiceNotes: "My voice notes",
  sharedWithMeVoiceNotes: "Shared with me",
  changePass: "Change password",
  changeTimeZone: "Change timezone",
  personalAssistant: "Personal assistant",
  personalAssistantSidebar: "PA",
  staffPerformance: "Staff performance",
  generalManager: "General manager",
  generalManagerSidebar: "GM",
  privateTitle: "Private listing",
  publicTitle: "Public listing",
  shareTitle: "Shared listing",
  momTitle: "MOM",
  momMyEvent: "My events",
  editMom: "Edit MOM",
  po: "Procurement and finance officer",
  hrOfficer: "HR officer",
  project: "Project",
  projectReport: "Project report",
  manageTask: "Manage task",
  myDocuments: "My documents",
  sharedWithMe: "Shared with me",
  customReport: "Custom report",
  companyReport: "Company report",
  applyLeave: "Apply for leave",
  manageRequest: "Manage request",
  leaveReq: "Leave request",
  officer: "Officer",
  hrofficer: "HR officer",
  privacyMenu: "Privacy",
  myLeaveReq: "My leave request",
  poRequest: "Procurement request",
  procurementofficer: 'Procurement officer'
};
