import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { showToast } from "../../../../common/Utils/ToastMessage";
import {
  useDeleteRequestMutation,
  useViewProcurementDetailsMutation,
} from "../../../../request/ProcurementRequest";
import Translate from "../../../../translation/utils/translateFunction";
import TaskInfo from "../../../AddTask/Components/TaskInfo";
import AlertPermission from "../../../AlertPermission";
import CommonImage from "../../../ImageGallery/CommonImage";
import NoDataFound from "../../../ManageTask/NoDataFound";
import Loader from "../../../staff/Owner/LoadingComp";
import VoiceNotes from "../../../VoiceNotes/Components/VoiceNotes";
import ProcurementDetails from "./ProcurementDetails";
import ProcurementStatus from "./ProcurementStatus";

const ProcurementDetailsView = () => {
  const { isRtl } = useSelector((state) => state.formanagement);
  const [attachmentsArray, setAttachmentsArray] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [voiceNoteObj, setVoiceNoteObj] = useState();
  const [statusData, setStatusData] = useState([]);
  const [showAttachment, setAttachmentBox] = useState(true);
  const [showVoiceNote, setShowVoiceNote] = useState(true);
  const [showTaskHistory, setShowTaskHistory] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [viewProcurementData, setViewProcurementData] = useState();
  const [showCompany, setShowCompany] = useState(true);
  const [showRequest, setShowRequest] = useState(true);
  const [showVendorInfo, setShowVendorInfo] = useState(true);
  const [showProcurementDetails, setShowProcurementDetails] = useState(true);
  const [showDeliveryInformation, setShowDeliveryInfo] = useState(true);
  const [showRequestTo, setShowRequestTo] = useState(true);
  const [showRequestBy, setShowRequestBy] = useState(true);
  const [show, setShow] = useState(false);
  const [
    viewProcurementDetails,
    {
      data: viewProcurementRequestData,
      isSuccess: successfulViewDetails,
      isError: errorInView,
      error: errorViewOwner,
      isLoading: loadingViewData,
    },
  ] = useViewProcurementDetailsMutation();
  const [
    deleteRequest,
    {
      data: serverResponseDelete,
      isSuccess: isDeleteSuccess,
      isLoading: isDeleteLoading,
    },
  ] = useDeleteRequestMutation();
  useEffect(() => {
    if (state) {
      viewProcurementDetails(state);
    }
  }, [viewProcurementDetails, state]);

  useEffect(() => {
    if (isDeleteSuccess && serverResponseDelete) {
      showToast({ message: serverResponseDelete?.message, type: "Success" });
      navigate(-1);
    }
    if (errorInView && errorViewOwner) {
      showToast({
        message: errorViewOwner?.data?.message,
        type: "error",
      });
    }

    if (successfulViewDetails && viewProcurementRequestData) {
      setViewProcurementData(viewProcurementRequestData?.data);
      const attachmentArray = [];

      const obj = {
        name: viewProcurementRequestData?.data?.attachment?.fileName,
        path: viewProcurementRequestData?.data?.attachment?.fileName,
        type: viewProcurementRequestData?.data?.attachment?.type,
        url: viewProcurementRequestData?.data?.attachment?.url,
      };
      attachmentArray.push(obj);

      setAttachmentsArray(attachmentArray);
      setAttachments([viewProcurementRequestData?.data?.attachment?.url]);
      viewProcurementRequestData?.data?.deliveryInformation?.voiceNote &&
        setVoiceNoteObj({
          byteCode:
            viewProcurementRequestData?.data?.deliveryInformation?.voiceNote
              ?.buffer,
          timeLength:
            viewProcurementRequestData?.data?.deliveryInformation?.voiceNote
              ?.timeLength,
          src: viewProcurementRequestData?.data?.deliveryInformation?.voiceNote
            ?.link,
          createdAt:
            viewProcurementRequestData?.data?.deliveryInformation?.voiceNote
              ?.createdAt,
        });
      setStatusData(viewProcurementRequestData?.data?.requestApprovers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewProcurementRequestData, errorViewOwner, isDeleteSuccess]);
  const onDeleteRequest = () => {
    deleteRequest(viewProcurementData?._id);
  };
  const onCancel = () => setShow(false);
  return (
    <>
      <>
        <div
          className={`section-body ${
            isRtl ? "rtlMargin" : "ltrMargin"
          } mt-3 mb-2`}
        >
          <div className="card">
            <div className="card-header">
              <i
                className="icon fe fe-arrow-left mr-3 icon-size backnavigation-iconColor cursor-pointer"
                onClick={() => navigate(-1)}
                title={Translate("common:backToolTip")}
              />
              <h5 className="page-title">
                {Translate("procurementRequest:procurementDetails")}
              </h5>
            </div>
          </div>
        </div>
        <div className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"}`}>
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <ProcurementDetails
                viewData={viewProcurementData}
                showApproval
                currentStateId={state}
                onSuccess={(value) => value && viewProcurementDetails(state)}
                onDelete={() => {
                  setShow(true);
                }}
                onEditClick={() =>
                  navigate(
                    "/manageRequest/procurementList/editProcurementRequest",
                    {
                      state: { requestData: viewProcurementData },
                    }
                  )
                }
              />

              {viewProcurementData?.status === "Disapproved" && (
                <TaskInfo
                  Title={Translate("common:noteTitle")}
                  Heading={Translate("procurementRequest:disapproveReason")}
                  Name={viewProcurementData?.reason}
                  isImportant
                />
              )}
              <div className="card">
                <div
                  className={`commentLabel cursor-pointer CommentCard_spacing ${
                    showCompany && "taskHeadingLineBottom"
                  }`}
                  onClick={() => setShowCompany(!showCompany)}
                >
                  <span className="taskHeading font-17">
                    {Translate("procurementRequest:company")}
                  </span>
                  <div>
                    <i
                      className={
                        showCompany ? "fa fa-caret-down " : "fa fa-caret-up"
                      }
                    />
                  </div>
                </div>
                {showCompany && (
                  <TaskInfo
                    Heading={Translate("procurementRequest:companyName")}
                    Name={viewProcurementData?.companyDetails?.name}
                  />
                )}
              </div>
              <div className="card">
                <div
                  className={`commentLabel cursor-pointer CommentCard_spacing ${
                    showRequest && "taskHeadingLineBottom"
                  }`}
                  onClick={() => setShowRequest(!showRequest)}
                >
                  <span className="taskHeading font-17">
                    {Translate("procurementRequest:request")}
                  </span>
                  <div>
                    <i
                      className={
                        showRequest ? "fa fa-caret-down " : "fa fa-caret-up"
                      }
                    />
                  </div>
                </div>
                {showRequest && (
                  <TaskInfo
                    Heading={Translate("procurementRequest:title")}
                    Name={viewProcurementData?.title}
                  />
                )}
              </div>
              <div className="card">
                <div
                  className={`commentLabel cursor-pointer CommentCard_spacing ${
                    showVendorInfo && "taskHeadingLineBottom"
                  }`}
                  onClick={() => setShowVendorInfo(!showVendorInfo)}
                >
                  <span className="taskHeading font-17">
                    {Translate("procurementRequest:vendorInfo")}
                  </span>
                  <div>
                    <i
                      className={
                        showVendorInfo ? "fa fa-caret-down " : "fa fa-caret-up"
                      }
                    />
                  </div>
                </div>
                {showVendorInfo && (
                  <TaskInfo
                    Heading={Translate("procurementRequest:vendorName")}
                    Name={viewProcurementData?.vendorInformation?.name}
                    subtitles={[
                      {
                        title: Translate(
                          "procurementRequest:vendorContactPersonName"
                        ),
                        name: viewProcurementData?.vendorInformation
                          ?.contactPerson,
                      },
                      {
                        title: Translate("procurementRequest:contactNumber"),
                        name: `+${viewProcurementData?.vendorInformation?.countryCode} ${viewProcurementData?.vendorInformation?.contactNumber}`,
                      },
                      {
                        title: Translate("procurementRequest:email"),
                        name: viewProcurementData?.vendorInformation?.email,
                      },
                    ]}
                  />
                )}
              </div>
              <div className="card">
                <div
                  className={`commentLabel cursor-pointer CommentCard_spacing ${
                    showProcurementDetails && "taskHeadingLineBottom"
                  }`}
                  onClick={() =>
                    setShowProcurementDetails(!showProcurementDetails)
                  }
                >
                  <span className="taskHeading font-17">
                    {Translate("procurementRequest:procurementDetails")}
                  </span>
                  <div>
                    <i
                      className={
                        showProcurementDetails
                          ? "fa fa-caret-down "
                          : "fa fa-caret-up"
                      }
                    />
                  </div>
                </div>
                {showProcurementDetails && (
                  <TaskInfo
                    Heading={Translate(
                      "procurementRequest:procurementDescription"
                    )}
                    Name={viewProcurementData?.requestDetails?.description}
                    subtitles={[
                      {
                        title: Translate("procurementRequest:quantity"),
                        name: viewProcurementData?.requestDetails?.quantity,
                      },
                      {
                        title: Translate("procurementRequest:unitPrice"),
                        name: `${viewProcurementData?.requestDetails?.unitPrice}`,
                      },
                      {
                        title: Translate("procurementRequest:totalCost"),
                        name: `${viewProcurementData?.requestDetails?.totalCost}`,
                      },
                      {
                        title: Translate(
                          "procurementRequest:procurementJustification"
                        ),
                        name: viewProcurementData?.requestDetails
                          ?.justification,
                      },
                    ]}
                  />
                )}
              </div>
              <div className="card">
                <div
                  className={`commentLabel cursor-pointer CommentCard_spacing ${
                    showDeliveryInformation && "taskHeadingLineBottom"
                  }`}
                  onClick={() => setShowDeliveryInfo(!showDeliveryInformation)}
                >
                  <span className="taskHeading font-17">
                    {Translate("procurementRequest:deliveryInfo")}
                  </span>
                  <div>
                    <i
                      className={
                        showDeliveryInformation
                          ? "fa fa-caret-down "
                          : "fa fa-caret-up"
                      }
                    />
                  </div>
                </div>
                {showDeliveryInformation && (
                  <TaskInfo
                    Title={Translate("procurementRequest:deliveryAddress")}
                    Heading={Translate("addOwner:residenceAddressPlaceholder")}
                    Name={
                      viewProcurementData?.deliveryInformation?.deliveryAddress
                        ?.address
                    }
                    isFromProcurementDetails
                    subtitles={[
                      {
                        title: Translate(
                          "addOwner:residenceAddressPlaceholder2"
                        ),
                        name: viewProcurementData?.deliveryInformation
                          ?.deliveryAddress?.apartment,
                      },
                      {
                        title: Translate("addOwner:country"),
                        name: viewProcurementData?.deliveryInformation
                          ?.deliveryAddress?.country,
                      },
                      {
                        title: Translate("addOwner:state"),
                        name: viewProcurementData?.deliveryInformation
                          ?.deliveryAddress?.state,
                      },
                      {
                        title: Translate("addOwner:city"),
                        name: viewProcurementData?.deliveryInformation
                          ?.deliveryAddress?.city,
                      },
                      {
                        title: Translate("procurementRequest:zipcode"),
                        name: viewProcurementData?.deliveryInformation
                          ?.deliveryAddress?.zipCode,
                      },
                      {
                        title: Translate("procurementRequest:deliveryDate"),
                        name: viewProcurementData?.deliveryInformation
                          ?.preferredDeliveryDate,
                      },
                    ]}
                  />
                )}
              </div>
              <div className="card">
                <div
                  className={`commentLabel cursor-pointer CommentCard_spacing ${
                    showRequestTo && "taskHeadingLineBottom"
                  }`}
                  onClick={() => setShowRequestTo(!showRequestTo)}
                >
                  <span className="taskHeading font-17">
                    {Translate("procurementRequest:requestTo")}
                  </span>
                  <div>
                    <i
                      className={
                        showRequestTo ? "fa fa-caret-down " : "fa fa-caret-up"
                      }
                    />
                  </div>
                </div>
                {showRequestTo && (
                  <TaskInfo
                    Heading={Translate("procurementRequest:name")}
                    Name={
                      viewProcurementData?.deliveryInformation?.requestTo?.name
                    }
                    subtitles={[
                      {
                        title: Translate("procurementRequest:email"),
                        name: viewProcurementData?.deliveryInformation
                          ?.requestTo?.email,
                      },
                      {
                        title: Translate("procurementRequest:designation"),
                        name: viewProcurementData?.deliveryInformation
                          ?.requestTo?.role?.type,
                      },
                    ]}
                  />
                )}
              </div>
              <div className="card">
                <div
                  className={`commentLabel cursor-pointer CommentCard_spacing ${
                    showRequestBy && "taskHeadingLineBottom"
                  }`}
                  onClick={() => setShowRequestBy(!showRequestBy)}
                >
                  <span className="taskHeading font-17">
                    {Translate("procurementRequest:requestBy")}
                  </span>
                  <div>
                    <i
                      className={
                        showRequestBy ? "fa fa-caret-down " : "fa fa-caret-up"
                      }
                    />
                  </div>
                </div>
                {showRequestBy && (
                  <TaskInfo
                    Heading={Translate("procurementRequest:name")}
                    Name={
                      viewProcurementData?.deliveryInformation?.requestBy?.name
                    }
                    subtitles={[
                      {
                        title: Translate("procurementRequest:email"),
                        name: viewProcurementData?.deliveryInformation
                          ?.requestBy?.email,
                      },
                      {
                        title: Translate("procurementRequest:designation"),
                        name: viewProcurementData?.deliveryInformation
                          ?.requestBy?.role?.type,
                      },
                    ]}
                  />
                )}
              </div>
            </div>
            <div className="col-md-8 col-sm-12" style={{ width: "100%" }}>
              <div className="card">
                <div
                  className={`commentLabel cursor-pointer CommentCard_spacing ${
                    showAttachment && "taskHeadingLineBottom"
                  }`}
                  onClick={() => setAttachmentBox(!showAttachment)}
                >
                  <span className="taskHeading font-17">
                    {Translate("manageTask:attacheMenttitle")}
                  </span>
                  <div>
                    <i
                      className={
                        showAttachment ? "fa fa-caret-down " : "fa fa-caret-up"
                      }
                    />
                  </div>
                </div>
                {showAttachment && (
                  <div className="CommentCard_spacing">
                    {viewProcurementRequestData?.data?.attachment?.url
                      ?.length ? (
                      <div className="AttachmentBoxStyling">
                        <CommonImage
                          images={attachments}
                          files={attachmentsArray}
                          icon={true}
                        />
                      </div>
                    ) : (
                      <NoDataFound
                        message={Translate("manageTask:noAttachmentMsg")}
                        isCenter
                        className="NoDataStyle"
                      />
                    )}
                  </div>
                )}
              </div>
              <div className="card">
                <div
                  className={`commentLabel cursor-pointer CommentCard_spacing ${
                    showVoiceNote && "taskHeadingLineBottom"
                  }`}
                  onClick={() => setShowVoiceNote(!showVoiceNote)}
                >
                  <span className="taskHeading font-17">
                    {Translate("manageTask:voiceNoteTitle")}
                  </span>
                  <div>
                    <i
                      className={
                        showVoiceNote ? "fa fa-caret-down " : "fa fa-caret-up"
                      }
                    />
                  </div>
                </div>
                {showVoiceNote && (
                  <div className="CommentCard_spacing">
                    {voiceNoteObj?.src || voiceNoteObj?.timeLength ? (
                      <div style={{ width: 450 }}>
                        <VoiceNotes
                          voiceObj={voiceNoteObj}
                          className={"voiceNote_spacing"}
                        />
                      </div>
                    ) : (
                      <NoDataFound
                        message={Translate("manageTask:noVoiceNote")}
                        isCenter
                        className="NoDataStyle"
                      />
                    )}
                  </div>
                )}
              </div>

              <div className="card">
                <div
                  className={`commentLabel cursor-pointer CommentCard_spacing ${
                    showTaskHistory && "taskHeadingLineBottom"
                  }`}
                  onClick={() => setShowTaskHistory(!showTaskHistory)}
                >
                  <span className="taskHeading font-17">
                    {Translate("procurementRequest:status")}
                  </span>
                  <div>
                    <i
                      className={
                        showTaskHistory ? "fa fa-caret-down " : "fa fa-caret-up"
                      }
                    />
                  </div>
                </div>
                {showTaskHistory && (
                  <div className="CommentCard_spacing">
                    <div className="commentListStyling">
                      {statusData?.length > 0 && (
                        <ProcurementStatus statusData={statusData} />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>

      {<Loader loading={loadingViewData || isDeleteLoading} />}
      {show && (
        <AlertPermission
          show={show}
          subTitle={Translate("procurementRequest:deleteTitle")}
          okTitle={Translate("common:delete")}
          onOk={onDeleteRequest}
          onCanel={onCancel}
        />
      )}
    </>
  );
};

export default ProcurementDetailsView;
