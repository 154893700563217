import * as yup from "yup";
import Translate from "../../../../translation/utils/translateFunction";
import { validateMobileSchema } from "../../../../common/Utils/commonFunction";
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const addProcurementRequestSchema = () => {
  const schema = yup.object().shape({
    companyId: yup
      .string()
      .trim()
      .required(Translate("addTask:companyReqError")),
    requestFor: yup
      .string()
      .trim()
      .required(Translate("procurementRequest:requestForError")),
    title: yup.string().trim().required(Translate("document:titleError")),
    name: yup
      .string()
      .trim()
      .required(Translate("procurementRequest:vendorNameError")),
    contactPerson: yup
      .string()
      .trim()
      .required(Translate("procurementRequest:vendorContactPersonNameError")),

    ...validateMobileSchema(
      "contactNumber",
      Translate("addOwner:numberError_2")
    ),
    email: yup
      .string()
      .matches(emailRegex, Translate("procurementRequest:invalidEmailError"))
      .trim()
      .required(Translate("procurementRequest:emailError")),

    description: yup
      .string()
      .trim()
      .required(Translate("document:descriptionError")),

    // Commented the auto calculation validation for total cost
    // quantity: yup
    //   .number()

    //   .required(Translate("procurementRequest:quantityError")),
    // unitPrice: yup
    //   .number()

    //   .required(Translate("procurementRequest:unitPriceError")),

    // totalCost: yup
    //   .number()
    //   .required(Translate("procurementRequest:totalCostError"))
    //   .positive("Must be positive")
    //   .test(
    //     "is-correct",
    //     `${Translate("procurementRequest:totalCostIncorrectError")}`,
    //     function (value) {
    //       const { quantity, unitPrice } = this.parent;
    //       return value === quantity * unitPrice;
    //     }
    //   ),
    quantity: yup
      .string()

      .required(Translate("procurementRequest:quantityError")),
    unitPrice: yup
      .string()

      .required(Translate("procurementRequest:unitPriceError")),

    totalCost: yup
      .string()
      .required(Translate("procurementRequest:totalCostError")),

    justification: yup
      .string()
      .trim()
      .required(Translate("procurementRequest:justificationError")),
    address: yup
      .string()
      .trim()
      .required(Translate("procurementRequest:addressError")),
    country: yup
      .string()
      .trim()
      .required(Translate("procurementRequest:countryError")),
    state: yup
      .string()
      .trim()
      .required(Translate("procurementRequest:stateError")),
    preferredDeliveryDate: yup
      .string()
      .trim()
      .required(Translate("procurementRequest:preferredDeliveryDateError")),
    startDate: yup
      .string()
      .trim()
      .required(Translate("procurementRequest:startDateError")),
    startTime: yup
      .string()
      .trim()
      .nullable()
      .required(Translate("procurementRequest:startTimeError")),
    dueDate: yup
      .string()
      .trim()
      .required(Translate("procurementRequest:dueDateError")),
    dueTime: yup
      .string()
      .trim()
      .nullable()
      .required(Translate("procurementRequest:dueTimeError")),
  });
  return schema;
};
