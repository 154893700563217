import { Methods, Urls } from "../../common/Utils/ApiConstants";
import { CreateApi } from "../CreateApi";

const ProcurementRequestCollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    addProcurementRequestApi: builder.mutation({
      query: (body) => {
        return {
          url: Urls.addProcurementRequest,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getRequestForList: builder.query({
      query: () => ({
        url: Urls.getRequestForList,
        method: Methods.get,
      }),
      transformResponse: (response) => {
        return response.data;
      },
    }),
    getProcurementPendingApprovalsListing: builder.mutation({
      query: ({ requestBody, pageNo }) => {
        return {
          url: Urls.getProcurementPendingList,
          method: Methods.post,
          body: requestBody,
          params: { pageNo },
        };
      },
    }),
    viewProcurementDetails: builder.mutation({
      query: (requestBody) => {
        return {
          url: `${Urls.getViewProcurementDetails}${requestBody}`,
          method: Methods.get,
        };
      },
    }),
    approveDisapproveRequest: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.approveDisapproveApi}${body._id}`,
          method: Methods.put,
          body: body.body,
        };
      },
    }),
    getMyRequestListApi: builder.mutation({
      query: ({ requestBody, pageNo }) => {
        return {
          url: Urls.getMyRequestListApi,
          method: Methods.post,
          body: requestBody,
          params: { pageNo },
        };
      },
    }),
    getPORequestListApi: builder.mutation({
      query: ({ requestBody, pageNo }) => {
        return {
          url: Urls.getPORequestListApi,
          method: Methods.post,
          body: requestBody,
          params: { pageNo },
        };
      },
    }),
    deleteRequest: builder.mutation({
      query: requestID => ({
        url: `${Urls.deletePORequest}${requestID}`,
        method: 'DELETE',
      }),
      transformResponse: (response) => {
        return response;
      },
    }),
    updatePORequest: builder.mutation({
      query: ({ poObj, poId }) => ({
        url: `${Urls.updatePORequest}${poId}`,
        method: 'PUT',
        body: poObj,
      }),
    }),
  }),
});
export const {
  useAddProcurementRequestApiMutation,
  useLazyGetRequestForListQuery,
  useGetProcurementPendingApprovalsListingMutation,
  useViewProcurementDetailsMutation,
  useApproveDisapproveRequestMutation,
  useGetMyRequestListApiMutation,
  useGetPORequestListApiMutation,
  useDeleteRequestMutation,
  useUpdatePORequestMutation
} = ProcurementRequestCollection;
