import Translate from "../../../../translation/utils/translateFunction";
import { CustomTitleCellRender } from "../../../common/CustomCellRender";
import { CustomPriorityCellRender } from "../../../ManageTask/Components/utils";


export const getProcurementRequestByColumns = (
    CustomSrCellRender,
    CustomActionCellRender
) => {
    const columns = [
        {
            field: "lineNo",
            headerName: Translate("addManager:srNoTableHeading"),
            sortable: false,
            disableColumnMenu: true,
            flex: 0.14,
            renderCell: CustomSrCellRender,
        },
        {
            field: "requestId",
            headerName: Translate("procurementRequest:requestID"),
            flex: 0.3,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) =>
                CustomTitleCellRender(`#${params?.row?.requestId}`),
        },
        {
            field: "title",
            headerName: Translate("procurementRequest:requestTitle"),
            flex: 0.4,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => CustomTitleCellRender(params?.row?.title),
        },
        {
            field: "companyName",
            headerName: Translate("procurementRequest:companyName"),
            flex: 0.3,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) =>
                CustomTitleCellRender(params?.row?.companyDetails?.name),
        },
        {
            field: "requestby",
            headerName: Translate("procurementRequest:requestBy"),
            flex: 0.3,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) =>
                CustomTitleCellRender(params?.row?.requestBy?.name),
        },
        {
            field: "dueDate",
            headerName: Translate("procurementRequest:dueDateAndTime"),
            flex: 0.25,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <div>
                        <div className="text-info">
                            {params?.row?.deliveryInformation.dueDate}
                        </div>
                        <div className="text-pink">
                            {params?.row?.deliveryInformation.dueTime}
                        </div>
                    </div>
                );
            },
        },

        {
            field: "priority",
            headerName: Translate("manageTask:priorityTitle"),
            flex: 0.25,
            sortable: false,
            disableColumnMenu: true,
            renderCell: CustomPriorityCellRender,
        },
        {
            field: "status",
            headerName: Translate("manageTask:status"),
            flex: 0.25,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "Action",
            headerName: Translate("addManager:actionTableHeading"),
            flex: 0.2,
            sortable: false,
            disableColumnMenu: true,
            renderCell: CustomActionCellRender,
        },
    ];
    return columns;
};
