import {
  jordanCountryCode,
  jordanCountryShortCode,
} from "../../../../common/Utils/ApiConstants";
import { getDayJs, getMoment } from "../../../../common/Utils/commonFunction";

export const initialValues = {
  companyId: "",
  requestFor: "",
  title: "",
  name: "",
  contactPerson: "",
  countryCode: jordanCountryCode,
  mobileShortCode: jordanCountryShortCode,
  contactNumber: "",
  email: "",
  description: "",
  quantity: "",
  unitPrice: "",
  totalCost: "",
  justification: "",
  address: "",
  apartment: "",
  country: "",
  state: "",
  city: "",
  zipCode: "",
  preferredDeliveryDate: getMoment().toDate(),
  requestTo: "",
  requestBy: "",
  startDate: getMoment().toDate(),
  dueDate: getMoment().add(1, "days").toDate(),
  startTime: getDayJs(),
  dueTime: getDayJs(),
  priority: "",
};
