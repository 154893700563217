import { Grid } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import CompanyHeader from "../../../../common/CompanyHeader";
import { colors } from "../../../../common/Theme/Colors";
import {
  StyledCard,
  StyledTypography
} from "../../../../common/Utils/StyledComponents";
import { showToast } from "../../../../common/Utils/ToastMessage";
import { useGetProcurementPendingApprovalsListingMutation } from "../../../../request/ProcurementRequest";
import Translate from "../../../../translation/utils/translateFunction";
import Searchbar from "../../../common/Searchbar";
import CompanyListing from "../../../ContactRepository/component";
import ListView from "../../../ListView";
import { getProcurementPendingApprovalColumns } from "./coulmn.data";

const ProcurementPendingApprovals = ({ fixNavbar }) => {
  const navigate = useNavigate();
  const { selectedCompanyList, isRtl } = useSelector(
    (state) => state.formanagement
  );
  const [loading, setLoading] = useState(true);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [search, setSearch] = useState();
  const [requestData, setRequestData] = useState();
  const [showModal, setShowModal] = useState(false);
  const lastPage = useRef(0);

  const [
    getPendingApprovalsListApi,
    {
      data: pendingApprovalsListData,
      isSuccess: successList,
      error: Error,
      isError: isListError,
      isLoading: loadingData,
    },
  ] = useGetProcurementPendingApprovalsListingMutation();

  let requestObject = useMemo(() => {
    const companies = selectedCompanyList?.length
      ? selectedCompanyList?.map((item) => item?.value)
      : [];

    return {
      companyId: companies,
      searchText: search ? search : "",
      requestFor: ["PO"],
      priority: ["High", "Emergency", "Medium", "Low"],
      status: ["Pending"],
      from: "",
      to: "",
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, currentPageNo, selectedCompanyList]);

  useEffect(() => {
    if (successList && pendingApprovalsListData) {
      const { paginatedResults, lastPageNo } = pendingApprovalsListData.data[0];
      setRequestData(paginatedResults);
      lastPage.current = lastPageNo;
      setLoading(false);
    }
    if (isListError && Error) {
      Error?.originalStatus !== 500 &&
        showToast({
          message: Translate("common:apiFailedMessage"),
          type: "error",
        });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successList, isListError]);
  const debouncedSearch = useDebouncedCallback(() => {
    getPendingApprovalsListApi({
      requestBody: requestObject,
      pageNo: currentPageNo,
    });
  }, 500);

  useEffect(() => {
    if (selectedCompanyList?.length) {
      setLoading(true);
      if (requestObject?.searchValue?.length) {
        debouncedSearch();
      } else {
        const finalRequestObject = {
          requestBody: requestObject,
          pageNo: currentPageNo,
        };
        getPendingApprovalsListApi(finalRequestObject);
      }
    } else {
      showToast({
        type: "info",
        message: Translate("addOwner:selectComapnyMessage"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestObject]);

  const CustomActionCellRender = (params) => {
    return (
      <td>
        <i
          className="m-2 fa fa-eye cursor-pointer manageIconColor"
          onClick={() =>
            navigate("/manageRequest/procurementList/viewDetails", {
              state: params?.row?._id,
            })
          }
        ></i>
      </td>
    );
  };

  const CustomSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPageNo - 1) * 10}
      </StyledTypography>
    );
  };
  const handleSearchText = (value) => {
    setCurrentPageNo(1);
    setSearch(value);
  };

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${fixNavbar ? "marginTop" : ""
        } `}
    >
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <div className="card-header card-header-spacing">
                  <i
                    className="icon fe fe-arrow-left mr-3 icon-size backnavigation-iconColor cursor-pointer"
                    onClick={() =>
                      navigate("/manageRequest/myProcurementRequestList")
                    }
                    data-toggle="tooltip"
                    title={"Go back"}
                  />
                  <CompanyHeader setShowModal={setShowModal} />
                  <div className="card-options">
                    <div className="input-group">
                      <Searchbar
                        handleSearchText={handleSearchText}
                        setSearch={setSearch}
                      />
                    </div>
                  </div>
                </div>
                <ListView
                  columns={getProcurementPendingApprovalColumns(
                    CustomSrCellRender,
                    CustomActionCellRender
                  )}
                  rows={requestData}
                  autoHeight
                  sortingMode="server"
                  onPaginationChange={setCurrentPageNo}
                  currentPage={currentPageNo}
                  showShimmer={loadingData}
                  showLoader={loading || loadingData}
                  totalPageCount={lastPage.current}
                />
              </div>
            </div>
          </div>
        </div>
        <CompanyListing
          handleModal={(value) => {
            setCurrentPageNo(1);
            setShowModal(value);
          }}
          showModal={showModal}
          selectedCompanyList={selectedCompanyList}
        />
      </StyledCard>
    </Grid>
  );
};

export default ProcurementPendingApprovals;
