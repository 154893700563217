export default {
  addProcurementRequest: "إضافة طلب",
  editProcurementRequest: "تحرير الطلب",
  request: "طلب",
  addCompany: "اختر الشركة",
  title: "عنوان",
  addTitle: "أضف عنوانا",
  vendorName: "اسم البائع",
  vendorContactPersonName: "مسؤول الاتصال بالمورد",
  vendorContactPersonNamePlaceholder: "الشخص الذي يمكن الاتصال به",
  quantity: "كمية",
  addQuantity: "أضف الكمية",
  unitPrice: "سعر الوحدة",
  unitPricePlaceholder: "السعر لكل وحدة",
  totalCost: "التكلفة الإجمالية",
  totalCostPlaceholder: "التكلفة الإجمالية",
  procurementJustification: "مبررات الشراء",
  procurementJustificationPlaceholder: "شرح سبب الحاجة إلى السلعة/الخدمة",
  email: "بريد إلكتروني",
  procurementDescription: "وصف السلعة/الخدمة",
  procurementDescriptionPlaceholder: "أضف وصفًا للصنف/الخدمة المراد شراؤها",
  deliveryAddress: "عنوان التسليم",
  deliveryDate: "تاريخ التسليم المفضل",
  requestTo: "طلب ل",
  requestBy: "الطلب بواسطة",
  vendorInfo: "معلومات البائع",
  procurementDetails: "تفاصيل المشتريات",
  deliveryInfo: "معلومات التوصيل",
  requestForError: "الطلب مطلوب",
  vendorNameError: "اسم البائع مطلوب",
  vendorContactPersonNameError: "اسم جهة الاتصال بالمورد مطلوب",
  emailError: "البريد الالكتروني مطلوب",
  quantityError: "الكمية مطلوبة",
  unitPriceError: "سعر الوحدة مطلوب",
  totalCostError: "التكلفة الإجمالية مطلوبة",
  justificationError: "التبرير مطلوب",
  addressError: "عنوان التسليم مطلوب",
  countryError: "الدولة مطلوبة",
  stateError: "الدولة مطلوبة",
  preferredDeliveryDateError: "مطلوب تاريخ التسليم المفضل",
  startDateError: "تاريخ البدء مطلوب",
  startTimeError: "مطلوب وقت البدء",
  dueDateError: "تاريخ الاستحقاق مطلوب",
  dueTimeError: "الوقت المناسب مطلوب",
  fileAddLimitMsg: "الحد الأقصى المسموح به هو 5 ملفات",
  totalCostIncorrectError: "التكلفة الإجمالية هي نتاج الكمية وسعر الوحدة",
  PendingApprovals: "الموافقات في انتظار",
  requestID: "طلب معرف",
  requestTitle: "طلب العنوان",
  requestBy: "الطلب بواسطة",
  dueDateAndTime: "تاريخ ووقت الاستحقاق",
  companyName: "اسم الشركة",
  priorityError: "الأولوية مطلوبة",
  company: "شركة",
  contactNumber: "رقم الاتصال",
  zipcode: "الرمز البريدي",
  name: "اسم",
  designation: "تعيين",
  status: "حالة",
  approveAlert: "هل أنت متأكد أنك تريد الموافقة على الطلب؟",
  disapproveAlert: "هل أنت متأكد أنك تريد رفض الطلب؟",
  disapproveReason: "سبب رفض الطلب:",
  approveTitle: "يعتمد",
  disapproveTitle: "لا أوافق",
  MYREQUEST: "طلبي",
  PENDINGREQUEST: "الموافقات في انتظار",
  disapproveReason: "سبب رفض الطلب",
  deleteTitle: "هل أنت متأكد أنك تريد حذف هذا الطلب؟",
  invalidEmailError: "معرف البريد الإلكتروني غير صالح",
  dueDateSameAsStartDateError:
    "يجب ألا يكون تاريخ الاستحقاق مساوياً لتاريخ البدء",
  requestTo: "طلب ل",
  noCompanyMessage: "يتم إنشاء المشتريات لجميع الشركات",
};
