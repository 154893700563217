import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAddressError } from "../../../../common/Utils/commonFunction";
import { formatDateYYYYMMDD } from "../../../../common/Utils/DateFormater";
import { showToast } from "../../../../common/Utils/ToastMessage";
import { useAddPODataMutation } from "../../../../request/Staff/ProcurementOfficer";
import Translate from "../../../../translation/utils/translateFunction";
import ProcurementForm from "../Components/ProcurementForm";
import { AddProcurementOfficerSchema } from "../Components/validation";
import { InitialValues } from "../Components/constants";

const AddProcurementOfficer = () => {
  const { state } = useLocation();
  const { userData } = useSelector((state) => state.formanagement);
  const navigate = useNavigate();
  const formikRef = useRef(null);

  const [reportTo, setReportTo] = useState({});
  const [filesAdded, setFilesAdded] = useState([]);
  const [images, setImages] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState('PO');

  const [addMoreProcurementOfficer, setAddMoreProcurementOfficer] =
    useState(false);

  const [
    addProcurementOfficerApi,
    {
      data: procurementOfficerData,
      isSuccess: isAddedSuccess,
      isLoading: isAddPOLoading,
      isError: isAddedError,
      error: errorAddPO,
    },
  ] = useAddPODataMutation();

  useEffect(() => {
    formikRef.current?.setFieldValue('staff', 'PO')
    if (isAddedSuccess && procurementOfficerData) {
      if (!procurementOfficerData?.success) {
        showToast({ message: procurementOfficerData?.message, type: "error" });
      } else {
        showToast({
          message: procurementOfficerData?.message,
          type: "success",
        });
      }
      formikRef.current.resetForm();
      InitialValues.dob = "";
      setFilesAdded([]);
      setImages([]);

      if (!addMoreProcurementOfficer) {
        navigate("/procurementOfficers");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [procurementOfficerData]);

  const onSubmit = (formValues) => {
    const {
      companyId,
      dob,
      countryCode,
      alternateMobile,
      alternateMobileCountryCode,
    } = formValues;

    let reqObj = {
      companyId: [companyId],
      name: formValues.name,
      gender: formValues?.gender.toUpperCase(),
      dob:
        moment(dob).creationData().format === "YYYY-MM-DD"
          ? dob
          : formatDateYYYYMMDD(dob),
      designation: formValues.designation,
      department: formValues.department,
      reportTo,
      countryCode: countryCode ? countryCode : "962",
      mobile: formValues?.mobile?.split("-")[1],
      mobileShortCode: formValues.mobileShortCode,
      alternateMobile: formValues?.alternateMobile
        ? formValues?.alternateMobile?.split("-")[1]
        : "",
      alternateMobileCountryCode: alternateMobile
        ? alternateMobileCountryCode
          ? alternateMobileCountryCode
          : "962"
        : "",
      alternateMobileShortCode: formValues?.alternateMobileShortCode,
      companyExtension: formValues.companyExtension,
      companyNumber: formValues.companyNumber,
      email: formValues.email,
      residenceAddress: {
        latitude: formValues.resLat,
        longitude: formValues.resLng,
        latlong: {
          latitude: formValues.resLat,
          longitude: formValues.resLng,
        },
        flat: formValues.resFlat,
        address: formValues.resAddress,
        country: formValues.resCountry,
        state: formValues.resState,
        city: formValues.resCity,
        zipCode: formValues.resZipCode,
      },
      workAddress: {
        latitude: formValues.workLat,
        longitude: formValues.workLng,
        latlong: {
          latitude: formValues.workLat,
          longitude: formValues.workLng,
        },
        flat: formValues.workFlat,
        address: formValues.workAddress,
        country: formValues.workCountry,
        state: formValues.workState,
        countryISO: "IN",
        city: formValues.workCity,
        zipCode: formValues.workZipCode,
      },
      emailVerified: "YES",
      mobileVerified: "YES",
      primary: userData?.primary,
      system: "NO",
      role: {
        type: "PO",
      },
      profileUrl: "",
    };

    let b64Object = {};
    if (
      formValues?.hrMobile?.length > 0 &&
      formValues?.hrMobile?.includes("-")
    ) {
      reqObj = {
        ...reqObj,
        hrMobile: formValues?.hrMobile?.split("-")[1],
        hrMobileCountryCode: formValues?.hrMobileCountryCode,
        hrMobileShortCode: formValues?.hrMobileShortCode,
      };
    } else {
      reqObj = {
        ...reqObj,
        hrMobile: "",
        hrMobileCountryCode: "",
        hrMobileShortCode: "",
      };
    }
    if (filesAdded?.length > 0) {
      b64Object = {
        ...filesAdded.map((ele) => ({
          url: ele?.b64,
          type: ele?.type,
          profileFileName: ele?.name,
          profileFileExt: ele?.name.split(".").pop(),
        }))[0],
      };
      reqObj = {
        ...reqObj,
        webProfileUrl: b64Object,
      };
    }

    addProcurementOfficerApi(reqObj);
  };

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={AddProcurementOfficerSchema}>
      {({ values, setFieldValue, handleSubmit, errors }) => {
        return (
          <ProcurementForm
            formikRef={formikRef}
            isAdd
            isLoading={isAddPOLoading}
            values={values}
            setFieldValue={setFieldValue}
            poData={state}
            handleSubmit={handleSubmit}
            setReportTo={setReportTo}
            filesAdded={filesAdded}
            setFilesAdded={setFilesAdded}
            images={images}
            setImages={setImages}
            setAddMoreProcurementOfficer={setAddMoreProcurementOfficer}
            selectedStaff={selectedStaff}
            setSelectedStaff={setSelectedStaff}
            error={errorAddPO}
            isError={isAddedError}
          />
        );
      }}
    </Formik>
  );
};

export default AddProcurementOfficer;
