import { Grid, Stack } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getManagetTaskColumns } from "../column.data";
import ListView from "../../ListView";
import { colors } from "../../../common/Theme/Colors";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import {
  useGetSelfAssignedTaskCollectionMutation,
  useLazyGetSelfAssignedTaskCollectionQuery,
} from "../../../request/Task";
import Translate from "../../../translation/utils/translateFunction";
import { projectTypeOptions, SUBTASK_PREFIX } from "../constants";
import { useSelector } from "react-redux";
import { MarkCriticalAndPinTask } from "../Components/utils";
import { taskStatusType } from "../../AddTask/constants";
import {
  useCriticalTaskMutation,
  usePinTaskMutation,
} from "../../../request/Task";
import IconifyIcon from "../../Icon";
import DateRangePicker from "../../DateRangePicker";
import DropDownField from "../../TextField/DropdownField";
import DashboardDropDown from "../../TextField/DashboardDropdown";
import { allFilterData, HomeScreenStrings } from "../../Dashboard/utils";
import DatePickerModal from "../../common/datePickerModal";
import { getDayJs, getMoment } from "../../../common/Utils/commonFunction";
import { moduleTypes } from "../../../common/users/userTypes";

const SelfAssignedTaskListing = ({ fixNavbar }) => {
  const { userData, isRtl } = useSelector((state) => state.formanagement);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [lastPageNo, setLastPageNo] = useState(0);
  const [SelfTaskListState, setSelfTaskListState] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [selectedStatus, setSelectedStatus] = useState("");
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedPriority, setSelectedPriority] = useState("");
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [selectedProject, setSelectedProject] = useState("ALL");
  const [projectOptions] = useState(projectTypeOptions);
  const [selectedTask, setSelectedTask] = useState("SELF");
  const [selectedRiskFactor, setSelectedRiskFactor] = useState("");
  const { state } = useLocation();
  const [showDatePickerModal, setShowDatePickerModal] = useState(false);
  const [selectedFilterGraph, setSelectedFilterGraph] = useState(
    HomeScreenStrings.MONTH
  );
  const [taskOptions, setTaskOptions] = useState([]);
  const [riskFactorOptions, setRiskFactorOptions] = useState([]);
  const [durationOptions, setDurationOptions] = useState([]);
  const isDashboardFiltersEnabled = userData?.moduleAccess?.find(
    (item) => Object.keys(item)[0] === moduleTypes.DASHBOARDFILTERS
  );

  const [value, setValue] = useState(getDayJs(getMoment().format()));
  const [filterSelectedDate, setFilterSelectedDate] = useState();

  const hideDatePickerModal = () => {
    setShowDatePickerModal(false);
  };

  useEffect(() => {
    if (userData && userData?.masterData) {
      const { priority, taskStatus, taskFor, riskFactor, duration } =
        userData?.masterData;

      const updatedPriorities = priority?.map((item) => ({
        label: item,
        value: item,
      }));
      const updatedStatus = taskStatus?.map((item) => ({
        label: item,
        value: item,
      }));
      const updatedTaskOptions = taskFor?.map((item) => ({
        label: item.name,
        value: item.value,
      }));
      const updatedRiskFactorOptions = riskFactor?.map((item) => ({
        label: item.name,
        value: item.value,
      }));
      const updatedDurationOptions = duration?.map((item) => ({
        label: item.name,
        value: item.value,
      }));
      setDurationOptions(updatedDurationOptions);
      setRiskFactorOptions(updatedRiskFactorOptions);
      setTaskOptions(updatedTaskOptions);
      setPriorityOptions(updatedPriorities);
      setStatusOptions(updatedStatus);
    }
  }, [userData]);

  const [
    fetchAllTask,
    {
      data: SelfTaskDataList,
      isLoading: isLoadingSelfTask,
      isError: isSelfTaskError,
      error: selfTaskError,
      isSuccess: isSuccessSelfAssignedTaskList,
      isFetching: selfAssignFetching,
    },
  ] = useGetSelfAssignedTaskCollectionMutation();

  const [pinTask, { data: pinTaskData }] = usePinTaskMutation({
    fixedCacheKey: "pinnedTask",
  });

  const [markCritical, { data: markCriticalData }] = useCriticalTaskMutation({
    fixedCacheKey: "criticalTask",
  });

  const requestPayload = useMemo(() => {
    const requestObject = {
      pageNo: currentPage,
    };
    const requestObjectWithFilters = {
      pageNo: currentPage,
      projectId: selectedProject,
      priority: selectedPriority ? [selectedPriority] : [],
      status: selectedStatus ? [selectedStatus] : [],
      duration: selectedFilterGraph,
      customDate: filterSelectedDate ? filterSelectedDate : undefined,
      riskFactor: selectedRiskFactor ? [selectedRiskFactor] : [],
      taskFor: selectedTask,
    };

    return isDashboardFiltersEnabled?.dashboardFilter?.isEnable
      ? requestObjectWithFilters
      : requestObject;
  }, [
    selectedProject,
    selectedPriority,
    selectedRiskFactor,
    selectedStatus,
    selectedTask,
    filterSelectedDate,
    selectedFilterGraph,
  ]);
  useEffect(() => {
    setIsLoading(true);
    fetchAllTask(requestPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestPayload, pinTaskData, markCriticalData]);

  useEffect(() => {
    if (SelfTaskDataList?.data && isSuccessSelfAssignedTaskList) {
      const { pageInfo, nodes } = SelfTaskDataList.data;
      setSelfTaskListState(nodes);
      setLastPageNo(pageInfo.lastPageNo);
      setIsLoading(false);
    }
    if (selfTaskError && isSelfTaskError) {
      showToast({
        type: "error",
        message: JSON.stringify(selfTaskError),
      });
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SelfTaskDataList, selfTaskError, isSuccessSelfAssignedTaskList]);

  const CustomActionCellRender = (params) => {
    const { taskNumber } = params?.row;
    const isSubtask = taskNumber?.startsWith(SUBTASK_PREFIX);
    const isCompleted = params?.row?.taskStatus === taskStatusType?.COMPLETED;

    const showCriticalFlag = isCompleted
      ? false
      : params?.row?.userDetails?.some(
          (details) => details?._id === userData?._id
        );

    return (
      <div className="headerMargin">
        <a
          href="/#"
          className="nav-link dropdown-toggle"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="fa fa-ellipsis-v manageIconColor" />
        </a>
        <div className="dropdown-menu dropdownAlignment dropdownIconMenu">
          <MarkCriticalAndPinTask
            taskId={params?.row?.taskId || params?.row?._id}
            showCriticalFlag={showCriticalFlag}
            hasFlagged={params?.row?.hasFlagged}
            isCompleted={isCompleted}
            hasPinned={params?.row?.hasPinned}
            markAsCritical={markCritical}
            pinTask={pinTask}
          />
          <div
            className="manageIconColor ml-3 cursor-pointer dropdown-item"
            onClick={() =>
              navigate(isSubtask ? "/subtask/view" : "/taskDetails", {
                state: {
                  taskId: params?.row?.taskId,
                  isTaskFilter: true,
                  navigateFrom: "/selfAssignedTask",
                },
              })
            }
          >
            <span className="manageIconColor fa fa-eye" />
            {Translate("manageTask:viewTask")}
          </div>
        </div>
      </div>
    );
  };

  const CustomSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };

  const showFilters = () => {
    setShowFilter(!showFilter);
  };

  const clearFilter = () => {
    setCurrentPage(1);
    setSelectedProject("ALL");
    setSelectedPriority("");
    setSelectedStatus("");
    setDateRange([null, null]);
    setSelectedTask("SELF");
  };

  useEffect(() => {
    if (state?.selectedFilter && state.isFromDashboard) {
      setSelectedTask(state.selectedFilter);
      setShowFilter(true);
    } else {
      setSelectedTask("SELF");
    }
  }, [state]);
  const setFilterValue = (item, placeholder) => {
    setCurrentPage(1);
    switch (placeholder) {
      case Translate("manageTask:statusPlaceHolder"):
        {
          item.target.value !== placeholder
            ? setSelectedStatus(item.target.value)
            : setSelectedStatus("");
        }
        break;
      case Translate("addTask:projectPlaceholder"):
        {
          item.target.value !== placeholder
            ? setSelectedProject(item.target.value)
            : setSelectedProject("ALL");
        }
        break;
      case Translate("manageTask:priorityPlaceHolder"):
        {
          item.target.value !== placeholder
            ? setSelectedPriority(item.target.value)
            : setSelectedPriority("");
        }
        break;
      case Translate("taskReport:staffLabel"):
        {
          item.target.value !== placeholder
            ? setSelectedTask(item.target.value)
            : setSelectedTask("");
        }
        break;
      case Translate("common:risk"):
        {
          item.target.value !== placeholder
            ? setSelectedRiskFactor(item.target.value)
            : setSelectedRiskFactor("");
        }
        break;
    }
  };

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}
    >
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <div className="card-header card-header-spacing ">
                  <Stack flexDirection={"row"} alignItems={"center"}>
                    <i
                      className="icon fe fe-arrow-left mr-3 icon-size backnavigation-iconColor cursor-pointer"
                      onClick={() => navigate("/manageTask")}
                      title={Translate("common:backToolTip")}
                    />
                    <h5 className="page-title">
                      {Translate("manageTask:selfAssignedTask")}
                    </h5>
                  </Stack>
                  {isDashboardFiltersEnabled?.dashboardFilter?.isEnable && (
                    <div
                      className={`card-options ${
                        showFilter && "manageTaskFilterDiv"
                      }`}
                    >
                      <div className="sort_stat commentTextField">
                        <div
                          className="filterIconBox headerMargin mx-2"
                          style={{
                            width: "100%",
                            textAlign: "end",
                          }}
                          onClick={() => {
                            showFilter && clearFilter();
                            showFilters(!showFilter);
                          }}
                        >
                          {showFilter ? (
                            <span
                              data-toggle="tooltip"
                              title={Translate("common:clearFilterToolTip")}
                            >
                              <IconifyIcon
                                icon="mdi:filter-off"
                                width="19"
                                className="dateRangeFilterIcon"
                              />
                            </span>
                          ) : (
                            <span
                              data-toggle="tooltip"
                              title={Translate("common:showFilterToolTip")}
                            >
                              <IconifyIcon
                                icon="fa:filter"
                                width="14"
                                className="dateRangeFilterIcon"
                              />
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {isDashboardFiltersEnabled?.dashboardFilter?.isEnable && (
                  <div className="sort_stat commentTextField formTitle">
                    {showFilter ? (
                      <div className="row mb-3" style={{ width: "100%" }}>
                        <div
                          className="col-lg-2 col-md-2 col-sm-4"
                          style={{ marginTop: "9px" }}
                        >
                          <StyledTypography
                            sx={{ color: colors.primary_009 }}
                            component="span"
                          >
                            {Translate("addTask:project")}
                          </StyledTypography>
                          <div className="multiselect_div managetaskCustomFilters">
                            <div className="form-group cursor-pointer">
                              <DropDownField
                                options={projectOptions}
                                className={
                                  "form-control textFieldHeight cursor-pointer"
                                }
                                placeholder={Translate(
                                  "addTask:projectPlaceholder"
                                )}
                                onChange={(item) =>
                                  setFilterValue(
                                    item,
                                    Translate("addTask:projectPlaceholder")
                                  )
                                }
                                value={selectedProject}
                                MenuProps={{
                                  PaperProps: {
                                    style: {
                                      width: "auto",
                                      overflowX: "auto",
                                    },
                                  },
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                }}
                                customMenuItemStyle={{ width: "100%" }}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-2 col-md-2 col-sm-4"
                          style={{ marginTop: "9px" }}
                        >
                          <StyledTypography
                            sx={{ color: colors.primary_009 }}
                            component="span"
                          >
                            {Translate("manageTask:priority")}
                          </StyledTypography>
                          <div className="managetaskCustomFilters">
                            <div className="form-group cursor-pointer">
                              <DropDownField
                                options={priorityOptions}
                                className={
                                  "form-control textFieldHeight cursor-pointer"
                                }
                                placeholder={Translate(
                                  "manageTask:priorityPlaceHolder"
                                )}
                                onChange={(item) =>
                                  setFilterValue(
                                    item,
                                    Translate("manageTask:priorityPlaceHolder")
                                  )
                                }
                                value={selectedPriority}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-2 col-md-3 col-sm-4"
                          style={{
                            marginTop: "9px",
                          }}
                        >
                          <StyledTypography
                            sx={{ color: colors.primary_009 }}
                            component="span"
                          >
                            {Translate("manageTask:status")}
                          </StyledTypography>
                          <div className="managetaskCustomFilters">
                            <div className="form-group cursor-pointer">
                              <DropDownField
                                options={statusOptions}
                                className={
                                  "form-control textFieldHeight cursor-pointer"
                                }
                                placeholder={Translate(
                                  "manageTask:statusPlaceHolder"
                                )}
                                onChange={(item) =>
                                  setFilterValue(
                                    item,
                                    Translate("manageTask:statusPlaceHolder")
                                  )
                                }
                                value={selectedStatus}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-2 col-md-2 col-sm-4"
                          style={{ marginTop: "9px" }}
                        >
                          <StyledTypography
                            sx={{ color: colors.primary_009 }}
                            component="span"
                          >
                            {Translate("common:task")}
                          </StyledTypography>
                          <div className="managetaskCustomFilters">
                            <div className="form-group cursor-pointer">
                              <DropDownField
                                options={taskOptions}
                                className={
                                  "form-control textFieldHeight cursor-pointer"
                                }
                                placeholder={Translate(
                                  "taskReport:staffPlaceHolder"
                                )}
                                onChange={(item) =>
                                  setFilterValue(
                                    item,
                                    Translate("taskReport:staffLabel")
                                  )
                                }
                                value={selectedTask}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-2 col-md-2 col-sm-4"
                          style={{ marginTop: "9px" }}
                        >
                          <StyledTypography
                            sx={{ color: colors.primary_009 }}
                            component="span"
                          >
                            {Translate("common:risk")}
                          </StyledTypography>
                          <div className="managetaskCustomFilters">
                            <div className="form-group cursor-pointer">
                              <DropDownField
                                options={riskFactorOptions}
                                className={
                                  "form-control textFieldHeight cursor-pointer"
                                }
                                placeholder={Translate(
                                  "taskReport:staffPlaceHolder"
                                )}
                                onChange={(item) =>
                                  setFilterValue(item, Translate("common:risk"))
                                }
                                value={selectedRiskFactor}
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          className="col-lg-3 col-md-3 col-sm-6 dateRangeBox"
                          style={{ marginTop: "9px" }}
                        >
                          <StyledTypography
                            sx={{ color: colors.primary_009 }}
                            component="span"
                          >
                            {Translate("common:duration")}
                          </StyledTypography>
                          <div style={{ width: "100%" }} id="manageTask">
                            <DashboardDropDown
                              options={durationOptions}
                              className={"cursor-pointer px-1"}
                              placeholder={Translate(
                                "taskReport:priorityPlaceHolder"
                              )}
                              onSelect={(value) => {
                                if (value === HomeScreenStrings.CUSTOM) {
                                  setShowDatePickerModal(true);
                                } else {
                                  setSelectedFilterGraph(value);
                                  setValue(getDayJs(getMoment().format()));
                                  setFilterSelectedDate();
                                }
                              }}
                              value={selectedFilterGraph}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}
                <div className="card-body">
                  <ListView
                    columns={getManagetTaskColumns(
                      CustomSrCellRender,
                      CustomActionCellRender,
                      false
                    )}
                    rows={SelfTaskListState}
                    autoHeight
                    sortingMode="server"
                    onPaginationChange={setCurrentPage}
                    currentPage={currentPage}
                    showShimmer={
                      selfAssignFetching || isLoading || isLoadingSelfTask
                    }
                    showLoader={isLoading || isLoadingSelfTask}
                    totalPageCount={lastPageNo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <DatePickerModal
          value={value}
          setValue={setValue}
          closeDatePickerModal={hideDatePickerModal}
          setFilterSelectedDate={setFilterSelectedDate}
          showDatePickerModal={showDatePickerModal}
          handleApply={() => setSelectedFilterGraph(HomeScreenStrings.CUSTOM)}
        />
      </StyledCard>
    </Grid>
  );
};
export default SelfAssignedTaskListing;
