export default {
  head: "Manage task",
  pendingApprovals: "Pending approvals",
  viewLinkedTask: "View linked subtask",
  linkedTask: "Linked subtask",
  assignedToMe: "Assigned to me",
  reportedByMe: "Reported by me",
  reallocationRequest: "Re-allocation request",
  reallocate: "Re-allocate",
  reAllocateTo: "Re-allocate to:",
  sendForApproval: "Send for approval",
  searchPlaceholder: "Search task",
  selfAssignedTask: "Self / team assigned task",
  pinned: "Pinned task",
  title: "Type",
  titlePlaceHolder: "Select type",
  priority: "Priority",
  priorityPlaceHolder: "Select priority",
  status: "Status",
  statusPlaceHolder: "Select status",
  selfAssigned: "Self / team assigned",
  assignToMe: "Assigned to me",
  reportByMe: "Reported by me",
  reportByPa: "Reported by PA",
  pinnedTask: "Pinned task",
  rejectedTask: "Rejected task",
  pendingApproval: "Pending approvals",
  reallocationRequest: "Re-allocation request",
  taskIdTitle: "Task id",
  taskTypeTitle: "Task type",
  taskNameTitle: "Task name",
  companyNameTitle: "Company name",
  dueDateTitle: "Due date",
  dueTimeTitle: "and time",
  dueDateAndTimeTitle: "Due date and time",
  teamTitle: "Team",
  statusTitle: "Status",
  progressBar: "Progress bar",
  actiontitle: "Action",
  priorityTitle: "Priority",
  selectCompanyMessage: "Please select at least one company",
  selectCompany: "Select company",
  noCompany: "No company found",
  voiceNoteTitle: "Voice note",
  noVoiceNote: "No voice note found",
  commentTitle: "Comment",
  loadMoreTitle: "Load more",
  attacheMenttitle: "Attachment",
  noAttachmentMsg: "No attachments found",
  historyTitle: "Task history",
  noHistory: "No task history found",
  assignedByPA: "Assigned by PA",
  relatedTask: "Related tasks",
  assigneeNameTitle: "Assignee name",
  reAllocateToTitle: "Re-allocate to",
  markasCritical: "Mark as critical",
  removeCritical: "Unmark as critical",
  pinTask: "Pin task",
  viewTask: "View task",
  moduleTypePlaceholder: "Module type",
  reportedByPA: "Reported by PA",
  unPinTask: "Unpin task",
  date: "Date",
  approveTask: "Pending self-approvals",
  projectTitle: "Project",
};
